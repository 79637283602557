import React from 'react';
import PropTypes from 'prop-types';
import CommunityRecipes from '@/components/CommunityRecipes';

const CommunityFeed = ({recipes}) => {
  const trackingData = {
    positionInUnit: 15,
    subunitName: 'community',
    unitName: 'main',
    unitType: 'feed'
  };

  return (
    <CommunityRecipes
      recipes={recipes}
      columns={6}
      trackingData={trackingData}
      displayMobileText={false}
      heading="Join the Tasty Community!"
      titleLinkText="Submit it here"
      mobileButtonText="Submit your own recipe"
      mobileButtonClassNames="button--tasty-secondary"
    />
  );
};

CommunityFeed.propTypes = {
  recipes: PropTypes.array,
};

export default CommunityFeed;
