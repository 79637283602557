
export const ACCOUNT_CID = '1bf35e8b-3d55-48a6-aef8-0ebfdd277df7';

export const CONNATIX_PLAYER_DEFAULTS = {
  generalBasic: 'e983b830-197e-448e-91b3-34fd5629dd1e',
  generalVertical: '893b8e6c-a215-48b7-b687-9626d047c35f',
  homepageBasic: '5d9646e0-c907-4b03-b35d-9d0cd8b110ee',
  homepageVertical: '461286f3-eeb0-4ab6-b235-3a3f22611a53',
  generalRecipe: '25a335c2-da2c-4750-9e12-17411575be5e',
};

export const CONNATIX_PLAYER_AU = {
  generalBasic: '013cbf59-7dd9-4162-b352-a7c0169e1a7c',
  generalVertical: '9b5ee8f8-e265-4839-b797-81d5f2292fb6',
  homepageBasic: '8dd67502-0244-42da-bc27-5a2102f1a30e',
  homepageVertical: '13a73a6c-38ed-4cdd-b64c-39f1419564eb',
  generalRecipe: '7a996776-38ca-4e06-928d-dda9189d4262',
};
