import { useState, useEffect, useRef } from 'react';

export const useIntersection = (options) => {
  let observer;
  const [observerEntry, setEntry] = useState({});
  const elRef = useRef();

  const cb = (entries) => {
    const entry = entries[0];
    setEntry(entries[0]);
    if (entry && entry.isIntersecting) {
      if (options.once && observer) {
        observer.disconnect();
      }
    }
  };

  useEffect(
    () => {
      if (observer) {
        observer.disconnect();
      }
      if (typeof window !== 'undefined') {
        const createOptions = {};
        if (options.rootMargin) {
          createOptions.rootMargin = options.rootMargin;
        }
        if ('threshold' in options) {
          createOptions.threshold = options.threshold;
        }
        observer = new IntersectionObserver(
          cb,
          createOptions
        );
      }
      if (observer && elRef) {
        observer.observe(elRef.current);
      }
      return () => observer.disconnect();
    },
    [elRef]
  );
  return { isIntersecting: 'isIntersecting' in observerEntry ? observerEntry.isIntersecting : false, elRef };
};
