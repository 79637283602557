import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { formatAnalyticsInfo, formatNameCET } from '@/utils/analytics-utils';
import { getUTCOffset } from '@/utils/misc-utils';
import { CDN_MAX_AGE_SECONDS } from '@/constants';

import HomeApi from '@/services/api/home';
import TastyApi from '@/services/api/tasty';

import AdWrapper from '../components/ads/AdWrapper';
import CommunityFeed from '../components/home/CommunityFeed';
import BestRecipes from '../components/home/BestRecipes';
import FeaturedArticle from '../components/home/FeaturedArticle';
import FeaturedPackage from '../components/home/FeaturedPackage';
import FeaturedRecipe from '../components/home/FeaturedRecipe';
import Feed from '../components/Feed';
import LatestArticles from '../components/home/LatestArticles';
import MoreArticles from '../components/home/MoreArticles';
import PageHead from '../components/PageHead';
import RecipeCarousel from '../components/home/RecipeCarousel';
import RecipeTips from '../components/home/RecipeTips';
import Shop from '../components/Shop';
import Shows from '../components/home/Shows';
import Stories from '../components/home/Stories';


const Home = ({ content, shop }) => {
  const {
    featured_recipe,
    featured_articles,
    featured_package,
    recent_recipes,
    recent_articles,
    best_recipes,
    more_articles,
    community_recipes,
    shows,
    stories,
    tips
  } = content;

  const [popularFeed, setPopularFeed] = useState(undefined);
  const [carouselFeeds, setCarouselFeeds] = useState([]);
  const [carouselError, setCarouselError] = useState(false);

  const loadCarousels = async() => {
    try {
      const carousels = await TastyApi.fetchCarousels({
        timezone: getUTCOffset(new Date())
      });
      setPopularFeed(carousels.popular);
      setCarouselFeeds(carousels.assorted);
    } catch (error) {
      setCarouselError(true);
    }
  };

  useEffect(() => {
    loadCarousels();
  }, []);


  const hasRecentArticles = recent_articles && recent_articles.content && recent_articles.content.length > 0;
  const hasMoreArticles = more_articles && more_articles.content && more_articles.content.length > 0;
  const hasFeaturedArticles = featured_articles && featured_articles.content && featured_articles.content.length > 0;

  return (
    <main
      className="home-page content-container xs-flex-grow-1 clearfix xs-col-12"
      // id for skip-to-content
      id="content"
    >
      {/* Tasty specific additions to head, including meta tags, links, and structured JSON for SEO */}
      <PageHead />
      <div className="home-container content-wrap xs-mx-auto">
        <AdWrapper breakpoint="desktop" type="toolbar" />
        <div className="xs-flex xs-flex-column md-flex-row">
          <div className="left-column">
            <FeaturedRecipe featured_recipe={featured_recipe} />
            {/* xs/sm Ad #1 */}
            <AdWrapper breakpoint="mobile" className="xs-my4" type="promo1-bp" />
          </div>
          <div className="right-column xs-flex xs-flex-column">
            {hasFeaturedArticles && <FeaturedArticle featured_articles={featured_articles.content} heading="What We’re Reading" href="/guides" />}
            <FeaturedPackage featured_package={featured_package} />
            {/* xs/sm Ad #2 */}
            <AdWrapper breakpoint="mobile" className="xs-mt4" type="promo2-bp" />
          </div>
        </div>
        {/* md and lg Ads #1 */}
        <AdWrapper breakpoint="md" className="md-my5" type="promo-inline1" />
        <AdWrapper breakpoint="lg" className="lg-my5" type="promo1-wide" />
        <div className="xs-mt2 xs-mb4 xs-mx2 md-mx1">
          <RecipeCarousel
            heading="Latest Recipes"
            recipes={recent_recipes.content}
            type="latest"
            positionInUnit={3}
            href="/latest"
          />
        </div>
        <div className="xs-my4 xs-mx2 md-mx1">
          {(popularFeed && popularFeed.items && !carouselError) &&
            <RecipeCarousel
              heading="Popular Recipes This Week"
              recipes={popularFeed.items}
              type="popular"
              positionInUnit={4}
            />
          }
        </div>
        <div className="xs-flex xs-flex-column md-flex-row xs-mt3 md-mt5">
          {hasRecentArticles &&
            <div className="left-column xs-mx2 md-ml1">
              <LatestArticles articles={recent_articles.content}/>
            </div>}
          {/* xs/sm Ad #3 */}
          <AdWrapper breakpoint="mobile" className="xs-mt2" type="promo3-bp" />
          <div className={`stories-container ${hasRecentArticles ? 'right-column' : ''}`}>
            <Stories stories={stories.content} url={stories.url} />
          </div>
        </div>
        {/* md and lg Ads #2 */}
        <AdWrapper breakpoint="md" className="md-mt2 md-mb2" type="promo-inline2" />
        <AdWrapper breakpoint="lg" className="lg-mt2 lg-mb2" type="promo2-wide" />
        <RecipeTips tips={tips.content}/>
        {/* xs/sm Ad #4 */}
        <AdWrapper breakpoint="mobile" className="xs-mt5 xs-mb2" type="promo4-bp" />
        <div className="xs-flex xs-flex-column md-flex-row xs-mt0 md-mt6">
          <div className="left-column xs-mt2 xs-mb4 md-mt3 md-mb0">
            <Shows shows={shows.content} url={shows.url} />
            {/* xs/sm Ad #5 */}
            <AdWrapper breakpoint="mobile" className="xs-mt4" type="promo-infinite-bp" />
          </div>
          <div className="right-column md-mt3 xs-flex xs-flex-justify-center md-block">
            <BestRecipes recipes={best_recipes.content} />
          </div>
        </div>
        {/* md and lg Ads #3 */}
        <AdWrapper breakpoint="md" className="md-mt5" type="promo-inline-infinite" />
        <AdWrapper breakpoint="lg" className="lg-mt6" type="promo-wide-infinite" />
        <div className="assorted-carousels md-mx1 md-flex md-flex-wrap">
          {(carouselFeeds && !carouselError) &&
            <>
              { carouselFeeds.map((feed, index) => {
                return (
                  <React.Fragment key={feed.name}>
                    <div className={`xs-mx2 md-mx0 xs-mb3 md-mb4 md-col-6 md-inline-block assorted-carousel assorted-carousel__${index % 2 === 0 ? 'left' : 'right'}`}>
                      <Feed
                        columns={4}
                        isScrollableMobile
                        hasSmallText
                        items={feed.items}
                        heading={feed.name}
                        subunitName={formatNameCET(feed.name)}
                        unitName='main'
                        unitType='feed'
                        positionInUnit={index + 10}
                      />
                    </div>
                    {/* xs/sm Ad #6 */}
                    {index === 1 && <AdWrapper breakpoint="mobile" className="xs-my4" type="promo-infinite-bp" key="carousel-ad" />}
                  </React.Fragment>
                );
              }
              )}
            </>
          }
        </div>
        {hasMoreArticles && <MoreArticles articles={more_articles.content}/>}
        {/* xs/sm Ad #7 */}
        <AdWrapper breakpoint="mobile" className="xs-mt4" type="promo-infinite-bp" />
        {/* md and lg Ads #4 */}
        <AdWrapper breakpoint="md" className="md-mt4" type="promo-inline-infinite" />
        <AdWrapper breakpoint="lg" className="lg-mt3" type="promo-wide-infinite" />
        <div className="community-recipes-container xs-mx2 md-mx1">
          <CommunityFeed recipes={community_recipes.content}/>
        </div>
      </div>
      {shop &&
        <div className="home__shopping-container xs-mt5">
          <div className="home__shopping content-wrap xs-mx-auto xs-px2 xs-pt4 xs-pb1">
            <Shop
              content={shop}
              trackingFields={{
                unit_name: 'main',
                unit_type: 'feed',
              }}
              positionOffset={16}
              variant="body"
            />
          </div>
        </div>}
    </main>
  );
};

Home.getInitialProps = async () => {
  let data = {};

  try {
    data = await HomeApi.fetchHomePage();
  } catch (e) {
    const statusCode = e.status ? e.status : 500;
    return {
      error: 'Error Fetching Home Page',
      statusCode,
    };
  }

  const analyticsInfo = formatAnalyticsInfo('home', {
    context_page_id: 'home',
  });

  return {
    pageType: 'home',
    maxAge: CDN_MAX_AGE_SECONDS.short,
    analyticsInfo,
    content: { ...data },
  };
};

Home.propTypes = {
  content: PropTypes.object,
};

export default Home;
