import React from 'react';
import PropTypes from 'prop-types';
import SvgIcon from '../../svg/SvgIcon';
import FeedItem from '../../FeedItem';
import TrackableLink from '../../TrackableLink';

const FeaturedPackage = ({ featured_package }) => {
  const itemName = `${featured_package.slug.split('/')[1]}_${
    featured_package.slug.split('/')[2]
  }`;

  const eventInfo = {
    item_name: itemName,
    position_in_subunit: null,
    position_in_unit: 2,
    subunit_name: 'featured_package',
    subunit_type: 'package',
    target_content_id: itemName,
    target_content_type: 'feed',
    unit_type: 'feed',
    unit_name: 'main',
  };

  const props = {
    subunitName: 'featured_package',
    unitName: 'main',
    unitType: 'feed',
    positionInUnit: 2,
  };

  return (
    <div className="featured-package-container xs-mx2 xs-mt4 md-ml0">
      <div className="header-container xs-mb1">
        {/* desktop version of header - is a link and has icon */}
        <TrackableLink
          className="xs-flex-row xs-hide md-block"
          linkTracking={{...eventInfo, item_type: 'text'}}
          url={featured_package.slug}
        >
          <h2 className="header-text extra-bold">
            {featured_package.title}
            <SvgIcon
              className="arrow md-ml1"
              width="11"
              height="15"
              id="caret-right"
              viewBox="0 0 512 512"
            />
          </h2>
        </TrackableLink>
        {/* mobile version of header - is not a link */}
        <h2 className="header-text extra-bold md-hide">{featured_package.title}</h2>
      </div>
      <ul className="featured-package-content list-unstyled">
        {featured_package.content.map((item, index) => (
          <FeedItem
            key={item.id}
            lazyLoad={false}
            item={item}
            hasLargeLabelMobile
            positionInSubunit={index}
            {...props}
          />
        ))}
      </ul>
      <TrackableLink
        className="see-all-btn button--tasty button--tasty-secondary extra-bold xs-flex xs-mt3 xs-flex-justify-center"
        linkTracking={{...eventInfo, item_type: 'button'}}
        url={featured_package.slug}
      >
        See all
      </TrackableLink>
    </div>
  );
};

FeaturedPackage.propTypes = {
  featured_package: PropTypes.object,
};

export default FeaturedPackage;
