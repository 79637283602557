import React from 'react';
import PropTypes from 'prop-types';
import TrackableLink from '../../TrackableLink';
import Video from '../../Video';

const sharedTrackingFields = {
  position_in_unit: 8,
  subunit_name: 'shows',
  subunit_type: 'package',
  unit_name: 'main',
  unit_type: 'feed'
};

const Show = ({ position, show }) => {
  const { show_name, show_youtube_url, video_id, video_urls, thumbnail_url, title } = show;
  const videoUrls = {
    fallback: {
      url: video_urls.small,
    },
    large_video: {
      url: video_urls.large
    }
  };
  return (
    <div className={`shows__show ${position === 0 ? 'xs-col-12' : 'xs-col-12 md-col-6'}`}>
      <div className="shows__video xs-overflow-hidden">
        <Video
          aspectRatio="16:9"
          autoplay={false}
          content={{
            type: 'home',
            show: {
              name: show_name,
            },
            tags: [],
            video_id: video_id
          }}
          hideControlsOnPause={true}
          muted={false}
          name={title}
          posterImg={thumbnail_url}
          posterSize={position === 0 ? 900 : 600}
          showBigButton={true}
          shouldLoop={false}
          trackingFields={{
            position_in_subunit: position,
            ...sharedTrackingFields
          }}

          videoUrls={videoUrls}
        />
      </div>
      <div className={`shows__video-title extra-bold ${position === 0 ? 'shows__video-title--first' : ''}`}>
        {title}
      </div>
      <TrackableLink
        className={`shows__video-link link-tasty extra-bold ${position === 0 ? 'shows__video-link--first' : ''}`}
        linkTracking={{
          item_name: show_youtube_url,
          item_type: 'text',
          position_in_subunit: position,
          target_content_id: show_youtube_url,
          target_content_type: 'url',
          target_content_url: show_youtube_url,
          ...sharedTrackingFields,
        }}
        openInNewWindow={true}
        url={show_youtube_url}
      >
        {show_name}
      </TrackableLink>
    </div>
  );
};

const Shows = ({ shows, url }) => {
  return (
    <div className="shows xs-mx2 md-mr0">
      <div className="shows__heading">
        <h2 className="shows__title extra-bold xs-text-2">Tasty Shows</h2>
        <TrackableLink
          className="shows__see-more link-tasty extra-bold xs-hide md-block xs-text-3"
          linkTracking={{
            item_name: url,
            item_type: 'text',
            position_in_subunit: null,
            target_content_id: url,
            target_content_type: 'url',
            target_content_url: url,
            ...sharedTrackingFields,
          }}
          openInNewWindow={true}
          url={url}
        >
          See all shows
        </TrackableLink>
      </div>
      {/* main width show */}
      <div className="shows__main xs-mb0 md-mb3">
        <Show
          show={shows[0]}
          key={0}
          position={0}
        />
      </div>
      <div className="shows__additional xs-block md-flex">
        <Show
          show={shows[1]}
          key={1}
          position={1}
        />
        <Show
          show={shows[2]}
          key={2}
          position={2}
        />
      </div>
      <TrackableLink
        className="shows__see-more button--tasty button--tasty-secondary xs-col-12 extra-bold"
        linkTracking={{
          item_name: url,
          item_type: 'button',
          position_in_subunit: null,
          target_content_id: url,
          target_content_type: 'url',
          target_content_url: url,
          ...sharedTrackingFields,
        }}
        openInNewWindow={true}
        url={url}
      >
        See all Tasty shows
      </TrackableLink>
    </div>
  );
};

Shows.propTypes = {
  shows: PropTypes.array.isRequired,
  url: PropTypes.string.isRequired,
};

export default Shows;
