import React from 'react';
import PropTypes from 'prop-types';
import FeaturedImage from '../FeaturedImage';
import TrackableLink from '../../TrackableLink';

const FeaturedRecipe = ({ featured_recipe }) => {
  // Largest possible sizes for featured image at each css breakpoint (based on solid md, sm and xs)
  const sizes = '(min-width: 52rem) 480px, (min-width: 40rem) 392px, 100vw';
  const headerText = "What We're Cooking";

  const eventInfo = {
    item_name: `${featured_recipe.id}`,
    item_type: 'splash',
    position_in_subunit: null,
    position_in_unit: 0,
    subunit_name: '',
    subunit_type: '',
    target_content_id: `${featured_recipe.id}`,
    target_content_type: featured_recipe.type,
    unit_type: 'feed',
    unit_name: 'main',
  };

  return (
    <div className="featured-recipe-container xs-flex xs-flex-column xs-mt0 xs-mx2 xs-pb4 md-mr0">
      <h2 className="extra-bold">{headerText}</h2>
      <div className="featured-recipe-content xs-flex xs-flex-column xs-mt0 md-mt3">
        <svg
          className="desk_back"
          width="821"
          height="1025"
          viewBox="0 0 820 880"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          preserveAspectRatio="none"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M166.819 790.513C94.1523 717.128 -38.7811 512.225 10.8189 279.688C24.1523 210.317 79.2189 64.0063 192.819 33.7352C334.819 -4.10371 436.819 -17.8633 536.819 33.7352C552.696 41.9276 569.783 49.9899 587.368 58.287C680.538 102.248 787.677 152.799 802.819 264.208C816.504 364.899 804.177 615.633 685.321 698.641C698.62 709.895 710.144 722.534 718.911 737.101C733.683 761.645 741.426 790.171 733.48 817.533C732.854 819.689 732.131 821.811 731.334 823.902C730.621 830.835 729.301 837.778 727.34 844.683C726.576 847.371 724.839 849.219 722.676 850.339C717.041 857.923 709.368 864.297 700.305 868.957C673.083 882.956 640.492 882.538 610.843 875.182C596.778 871.694 583.16 867.363 569.566 863.041C548.682 856.399 527.855 849.777 505.557 846.254C474.203 841.302 430.427 836.232 384.901 830.958C324.171 823.923 260.326 816.528 218.696 808.572C194.908 804.026 179.009 797.881 168.835 790.189L166.819 790.513Z"
            fill="#F9E932"
          />
        </svg>

        <svg
          className="mobile_back"
          width="293"
          height="257"
          viewBox="0 0 293 257"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          preserveAspectRatio="none"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M289.189 142.086C291.473 137.111 292.579 131.692 292.4 126.27C292.943 125.079 293.055 123.651 292.465 122.091C290.948 118.084 289.144 114.245 287.084 110.59C286.712 109.317 286.297 108.05 285.829 106.795C279.893 90.8649 266.854 78.9577 252.168 71.0914C226.249 57.2077 195.721 58.9497 166.776 61.6425C164.685 60.9891 162.607 60.2734 160.539 59.4908C143.074 52.8758 126.725 43.4484 109.808 35.5849C77.6063 20.6161 43.8006 7.92333 8.45044 3.04325C-26.6984 -1.80898 -66.4095 0.379456 -96.1773 21.761C-123.137 41.126 -133.831 74.0926 -132.175 106.227C-130.386 140.938 -114.412 174.256 -91.3897 199.903C-44.4118 252.235 32.019 270.375 97.4264 244.041C133.308 229.595 162.981 203.645 198.203 187.925C215.921 180.017 234.807 177.487 253.072 171.421C268.254 166.38 282.327 157.028 289.189 142.086"
            fill="#F9E932"
          />
        </svg>
        <TrackableLink
          className="link-tasty extra-bold featured-recipe_link"
          linkTracking={{...eventInfo}}
          url={`/${featured_recipe.type}/${featured_recipe.slug}`}
        >
          <div className={`${featured_recipe.brand_name ? 'xs-mb3' : 'xs-mb1'} xs-z1 md-mt0`}>
            <p className="featured-recipe-name xs-hide md-block">
              {featured_recipe.name}
            </p>
            {featured_recipe.brand_name && (
              <p className="xs-hide md-block xs-text-3 md-text-2 regular">
                  Presented by {featured_recipe.brand_name}
              </p>
            )}
          </div>
          <FeaturedImage className="xs-z1" item={featured_recipe} mediaSizes={sizes} />
          <p className="featured-recipe-name xs-mt1 md-hide">
            {featured_recipe.name}
          </p>
          {featured_recipe.brand_name && (
            <p className="md-hide xs-text-3 md-text-2 regular">
              Presented by {featured_recipe.brand_name}
            </p>
          )}
        </TrackableLink>
      </div>
    </div>
  );
};

FeaturedRecipe.propTypes = {
  featured_recipe: PropTypes.object,
};

export default FeaturedRecipe;
