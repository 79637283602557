import Ad from '../Ad';

const AdWrapper = ({ type, className, breakpoint, mode }) => {
  return (
    <div className={`
      ad-wrapper
      ${breakpoint ? `ad-wrapper--${breakpoint}` : ''}
      ${className ? className : ''}
      ${mode ? `ad-wrapper--${mode}` : ''}`
    }>
      <Ad type={type} />
    </div>
  );
};

export default AdWrapper;
