import React from 'react';
import PropTypes from 'prop-types';
import Feed from '../../Feed';

const FeaturedArticle = ({ featured_articles, heading, href }) => {
  const [featured_article] = featured_articles;
  const sizes = '(min-width: 52rem) 480px, (min-width: 40rem) 392px, 100vw';

  const props = {
    subunitName: 'featured_article',
    unitName: 'main',
    unitType: 'feed',
    positionInUnit: 1,
    positionInSubunit: 0
  };

  return (
    <div className="featured-article-container xs-mx2 xs-flex xs-flex-column md-ml0">
      <Feed
        hasWideImage
        columns={1}
        key={featured_article.id}
        items={[featured_article]}
        lazyLoad={false}
        mediaSizes={sizes}
        heading={heading}
        href={href}
        {...props}
      />
    </div>
  );
};

FeaturedArticle.propTypes = {
  featured_article: PropTypes.array,
};

export default FeaturedArticle;
