/* eslint-disable react/no-unknown-property */
export function VideoThumbnailImage({ aspectRatio, poster, posterFetchPriority, name }) {
  return (
    <div
      className={`video-thumbnail image-${aspectRatio.split(':').join('-')}`}
    >
      <img src={poster} fetchpriority={posterFetchPriority} alt={name}/>
    </div>
  );
}
