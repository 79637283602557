import React from 'react';
import PropTypes from 'prop-types';
import { CdnImage } from '@buzzfeed/react-components';
import TrackableLink from '../../TrackableLink';

const RecipeTips = ({tips}) => {
  return (
    <div className="recipe-tips xs-mx2">
      <h2 className="extra-bold xs-text-2"> What You&apos;re Making </h2>
      <div className="recipe-tips_container">
        {tips.map((tip, index) => (
          <div className="recipe-tips_card-wrapper" key={tip.author}>
            <div className="recipe-tips_card">
              <div className="xs-flex xs-flex-justify-space-between">
                <div className="recipe-tips_text-wrapper">
                  <div className="xs-flex xs-flex-align-center xs-mb2">
                    <CdnImage
                      alt={`user avatar for ${tip.author}`}
                      className="recipe-tips_author-image"
                      lazy={true}
                      src={`${tip.author_avatar_url}?downsize=80:*`}
                    />
                    <div className="extra-bold xs-text-5 xs-ml1 xs-mb1"> {tip.author} </div>
                  </div>
                  <div className="xs-text-3 lg-text-2 italic"> &quot;{tip.tip_body}&quot; </div>
                </div>
                <CdnImage
                  alt={`tip photo for ${tip.recipe_name}`}
                  className="recipe-tips_recipe-image"
                  lazy={true}
                  src={`${tip.tip_photo}?downsize=200:*`}
                />
              </div>
              <TrackableLink
                className="link-tasty xs-mt2 xs-text-5 md-text-4 extra-bold decoration-underline"
                linkTracking={{
                  item_name: tip.recipe_id,
                  item_type: 'text',
                  position_in_subunit: index,
                  target_content_id: tip.recipe_id,
                  target_content_type: 'recipe',
                  position_in_unit: 7,
                  subunit_name: 'highlighted_tips',
                  subunit_type: 'package',
                  unit_name: 'main',
                  unit_type: 'feed'
                }}
                url={`/recipe/${tip.recipe_slug}`}
              >
                {tip.recipe_name}
              </TrackableLink>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

RecipeTips.propTypes = {
  tips: PropTypes.array,
};

export default RecipeTips;
