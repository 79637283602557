import React from 'react';
import PropTypes from 'prop-types';
import Feed from '../../Feed';

const RecipeCarousel = ({ heading, recipes, type, positionInUnit, href }) => {

  return (
    <Feed
      columns={6}
      isScrollableMobile
      items={recipes}
      hasSmallText
      heading={heading}
      positionInUnit={positionInUnit}
      subunitName={`${type}_recipes`}
      unitName='main'
      unitType='feed'
      href={href}
    />
  );
};

RecipeCarousel.propTypes = {
  heading: PropTypes.string,
  recipes: PropTypes.array,
  href: PropTypes.string,
};

export default RecipeCarousel;
