import React from 'react';
import PropTypes from 'prop-types';
import Feed from '@/components/Feed';
import FeedHeading from '@/components/FeedHeading';
import TrackableLink from '@/components/TrackableLink';

const CommunityRecipes = (props) => {
  const {
    recipes,
    columns,
    trackingData,
    heading,
    titleLinkText,
    displayMobileText,
    mobileButtonText,
    mobileButtonClassNames = ''
  } = props;
  const linkTracking = {
    item_name: 'recipe_submission',
    position_in_unit: trackingData.positionInUnit,
    subunit_name: trackingData.subunitName,
    subunit_type: 'package',
    target_content_id: 'recipe_submission',
    target_content_type: 'form',
    unit_name: trackingData.unitName,
    unit_type: trackingData.unitType
  };

  return (
    <>
      <div className="xs-flex xs-flex-column md-mb2">
        <FeedHeading noSmallMargins heading={heading}/>
        <span className={`md-ml1 xs-text-3 ${!displayMobileText ? 'xs-hide md-inline-block' : ''}`}>
          Have a recipe of your own to share? {' '} {/* force space here - do not delete */}
          <TrackableLink
            className={`link-tasty decoration-underline extra-bold ${displayMobileText ? 'xs-hide md-inline-block' : ''}`}
            linkTracking={{...linkTracking, item_type: 'text'}}
            openInNewWindow={true}
            url="/submit-recipe"
          >
            {titleLinkText}
          </TrackableLink>
        </span>
      </div>
      <div className="xs-flex xs-flex-column">
        <Feed
          isScrollableMobile
          columns={columns}
          items={recipes}
          subunitName={trackingData.subunitName}
          unitName={trackingData.unitName}
          unitType={trackingData.unitType}
          positionInUnit={trackingData.positionInUnit}
        />
        <TrackableLink
          className={`button--tasty extra-bold xs-mt1 ${mobileButtonClassNames}`}
          linkTracking={{...linkTracking, item_type: 'button'}}
          openInNewWindow={true}
          url="/submit-recipe"
        >
          {mobileButtonText}
        </TrackableLink>
      </div>
    </>
  );
};

CommunityRecipes.propTypes = {
  recipes: PropTypes.array,
  columns: PropTypes.number,
  trackingData: PropTypes.object,
  heading: PropTypes.string,
  titleLinkText: PropTypes.string,
  displayMobileText: PropTypes.bool,
  mobileButtonText: PropTypes.string,
  mobileButtonClassNames: PropTypes.string
};

export default CommunityRecipes;
