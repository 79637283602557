import React from 'react';
import PropTypes from 'prop-types';
import Feed from '../../Feed';

const MoreArticles = ({articles}) => {

  return (
    <div className="xs-mt5 xs-mx2 md-mt0 md-mx1">
      <Feed
        isScrollableMobile
        columns={4}
        hasWideImage
        items={articles}
        heading="More Guides"
        subunitName="more_articles"
        unitName='main'
        unitType='feed'
        positionInUnit={14}
        href="/guides"
      />
    </div>
  );
};

MoreArticles.propTypes = {
  articles: PropTypes.array,
};

export default MoreArticles;
