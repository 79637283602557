import { API_PROXY_URL } from '@/constants';
import { timedFetch } from './_base';

const PROXY_SUFFIX = '/api/proxy/home';
const ENDPOINT_PREFIX = `${API_PROXY_URL}${PROXY_SUFFIX}`;

class HomeApi {

  fetchHomePage() {
    return timedFetch(ENDPOINT_PREFIX);
  }
}

export default new HomeApi();
