import React from 'react';
import PropTypes from 'prop-types';
import { CdnImage } from '@buzzfeed/react-components';

const FeaturedImage = ({ item, mediaSizes, className }) => {
  return (
    <div className={`featured-img-wrapper xs-overflow-hidden ${className}`}>
      <div className='featured-img-aspect-ratio-wrapper'>
        <CdnImage
          alt={item.thumbnail_alt_text}
          className="featured-img"
          aria-hidden="true"
          src={item.thumb_standard}
          srcSet={`${item.thumb_standard} 300w, ${item.thumb_big} 600w, ${item.thumb_dblbig} 900w`}
          sizes={mediaSizes}
          fetchpriority="high"
        />
      </div>
    </div>
  );
};

FeaturedImage.propTypes = {
  item: PropTypes.object,
  mediaSizes: PropTypes.string,
  className: PropTypes.string,
};

export default FeaturedImage;
