import React from 'react';
import PropTypes from 'prop-types';
import Feed from '../../Feed';

const LatestArticles = ({articles}) => {

  return (
    <Feed
      isScrollableMobile
      hasWideImage
      items={articles}
      heading="Latest Guides"
      subunitName="latest_articles"
      unitName='main'
      unitType='feed'
      positionInUnit={5}
      href="/guides"
    />
  );
};

LatestArticles.propTypes = {
  articles: PropTypes.array,
};

export default LatestArticles;
