import React from 'react';
import PropTypes from 'prop-types';

function InspiredBy(props) {
  return (
    <p className={`inspired-by xs-mt05 sm-mx0 xs-text-5 text-gray-lightest ${props.className}`}>
      {props.isLicensedVideo ? 'Recipe' : 'Inspired'} by
      <a
        className="link-tasty"
        href={props.url}
        rel="noopener noreferrer"
        target="_blank"
      > {props.host}</a>
    </p>
  );
}

InspiredBy.defaultProps = {
  className: '',
};

InspiredBy.propTypes = {
  className: PropTypes.string,
  host: PropTypes.string.isRequired,
  isLicensedVideo: PropTypes.bool.isRequired,
  url: PropTypes.string.isRequired,
};

export default InspiredBy;
