import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { ResizeContext, RouteContext } from '@/context';
import { withErrorBoundary } from '@buzzfeed/react-components';
import { useIntersection } from '@/hooks/useIntersection';
import { captureException } from '@sentry/nextjs';
import ConnatixPlayer from '../ConnatixPlayer';
import { VideoThumbnailImage } from '../VideoThumbnailImage';
import InspiredBy from '../recipe/InspiredBy';

const ratioImages = {
  '9:16':
    'iVBORw0KGgoAAAANSUhEUgAAAAkAAAAQCAQAAABuQZ3IAAAAEElEQVR42mNkwACMo0K4hQALSAARu3DAkAAAAABJRU5ErkJggg==',
  '40:71':
    'iVBORw0KGgoAAAANSUhEUgAAACgAAABHCAQAAADfVc1pAAAALklEQVR42u3MMQEAAAwCoNm/9Er4CQHIlUUoFAqFQqFQKBQKhUKhUCgUCoXD4QM/YABIBxOdlQAAAABJRU5ErkJggg==',
};

function Video(props) {
  const [mounted, setMounted] = useState(false);
  const { pageType } = useContext(RouteContext);
  const { breakpoint } = useContext(ResizeContext);


  const { isIntersecting, elRef } = useIntersection({
    once: true,
    threshold: 0,
  });

  useEffect(() => {
    if (isIntersecting) {
      setMounted(true);
    }
  }, [isIntersecting]); // eslint-disable-line react-hooks/exhaustive-deps

  const {
    aspectRatio,
    page,
    posterImg,
    posterSize,
    posterFetchPriority = 'auto',
    name,
    autoplay,
    endTime,
    muted,
    showBigButton,
    startTime,
    trackingFields,
    videoUrls
  } = props;

  const isVertical =
    ['9:16', '40:71'].includes(aspectRatio) &&
    ['recipe', 'compilation', 'home'].includes(page);
  const isSquare =
    ['1:1'].includes(aspectRatio) && ['recipe', 'compilation'].includes(page);
  const isWide =
    ['16:9'].includes(aspectRatio) && ['recipe', 'compilation'].includes(page);
  const isPotrait =
    ['4:5'].includes(aspectRatio) && ['recipe', 'compilation'].includes(page);
  const { inspired_by, is_licensed_video, video_id } = props.content;

  // build poster image url image
  const poster = `${posterImg}?output-format=auto&output-quality=auto&resize=${posterSize}:*`;
  let content = null;
  let renderId = `render-${video_id}`;
  let videoUrl = '';

  if (!mounted) {
    // show a thumbnail while video code, consent and other elements load, to avoid CLS
    content = (
      <VideoThumbnailImage
        aspectRatio={aspectRatio}
        poster={poster}
        posterFetchPriority={posterFetchPriority}
        name={name}
      />
    );
  } else {
    const isTablet = breakpoint === 'sm';

    if (videoUrls && Object.keys(videoUrls).length > 0) {
      videoUrl = videoUrls.fallback.url;

      if (isTablet && videoUrls.large_video) {
        videoUrl = videoUrls.large_video.url;
      }
    }

    let loop = false;
    if ('shouldLoop' in props) {
      // if 'shouldLoop' is an explicit prop, use whatever value that is
      loop = props.shouldLoop;
    }

    content = (
      <ConnatixPlayer
        aspectRatio={aspectRatio}
        autoplay={autoplay}
        endTime={endTime}
        isPotrait={isPotrait}
        isSquare={isSquare}
        isVertical={isVertical}
        isWide={isWide}
        pageType={pageType}
        poster={poster}
        posterFetchPriority={posterFetchPriority}
        loop={loop}
        muted={muted}
        name={name}
        renderId={video_id}
        showBigButton={showBigButton}
        startTime={startTime}
        trackingFields={trackingFields}
        videoId={video_id}
        videoUrl={videoUrl}
      />
    );
  }

  return (
    <div
      id={renderId}
      aria-label={pageType === 'recipe' ? 'Recipe preparation steps' : null}
      className={`video video--${pageType} ${
        isVertical ? 'video--vertical' : ''
      }`}
      ref={elRef}
    >
      <div className={`video-wrap ${isVertical ? 'video-wrap--vertical' : ''}`}>
        <div
          className={`video-content ${
            isVertical ? 'video-content--vertical' : ''
          } xs-relative`}
        >
          {/* Adds an image spacer for vertical videos that is used to help style
           *   to maintain aspect ratio based on height of content; On most browsers,
           *   the aspect-ratio alone can handle this; but this helps get decent
           *   styling for the remaining until global browser support rises even
           *   more.  */}
          {isVertical && (
            <img
              aria-hidden='true'
              className='video-sizer'
              src={`data:image/gif;base64,${ratioImages[aspectRatio]}`}
            />
          )}
          {content}
        </div>
        {pageType === 'recipe' && inspired_by && (
          <InspiredBy
            className='xs-mx2'
            host={inspired_by.host}
            isLicensedVideo={is_licensed_video}
            url={inspired_by.url}
          />
        )}
      </div>
    </div>
  );
}

Video.propTypes = {
  autoplay: PropTypes.bool,
  aspectRatio: PropTypes.oneOf(['1:1', '16:9', '9:16', '40:71', '4:5'])
    .isRequired,
  content: PropTypes.object.isRequired,
  endTime: PropTypes.number.isRequired,
  hideControlsOnPause: PropTypes.bool.isRequired,
  muted: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired,
  posterImg: PropTypes.string.isRequired,
  posterSize: PropTypes.number.isRequired,
  posterFetchPriority: PropTypes.string,
  shouldLoop: PropTypes.bool,
  showBigButton: PropTypes.bool.isRequired,
  startTime: PropTypes.number.isRequired,
  trackingFields: PropTypes.object.isRequired,
  videoHlsUrl: PropTypes.string,
  videoUrls: PropTypes.object.isRequired,
};

Video.defaultProps = {
  autoplay: true,
  endTime: 0,
  hideControlsOnPause: false,
  muted: true,
  posterSize: 600,
  showBigButton: false,
  startTime: 0,
};

export default withErrorBoundary(Video, {
  onError: captureException,
});
