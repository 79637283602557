import React from 'react';
import PropTypes from 'prop-types';
import TrackableLink from '../../TrackableLink';
import Video from '../../Video';

const sharedTrackingFields = {
  position_in_unit: 6,
  subunit_name: 'stories',
  subunit_type: 'package',
  unit_name: 'main',
  unit_type: 'feed'
};

const FollowLink = ({ className, type, url }) => {
  return (
    <TrackableLink
      className={`
        stories__follow
        ${type === 'button' ? 'button--tasty button--tasty-secondary extra-bold' : 'link-tasty extra-bold'}
        ${className || ''}
      `}
      linkTracking={{
        item_name: url,
        item_type: type,
        position_in_subunit: 0,
        target_content_id: url,
        target_content_type: 'url',
        target_content_url: url,
        ...sharedTrackingFields,
      }}
      openInNewWindow={true}
      url={url}
    >
      Follow Tasty on Instagram
    </TrackableLink>
  );
};

const Stories = ({ stories, url, page = 'home' }) => {
  const { caption, thumbnail_url, video_id, video_urls } = stories[0];
  const videoUrls = {
    fallback: {
      url: video_urls.large, // for this placement, the small video is too small on all breakpoints
    },
    large_video: {
      url: video_urls.large
    }
  };
  return (
    <div className="stories xs-my3 xs-mx2 md-my0">
      <h2 className="stories__heading extra-bold xs-text-2">
        Tasty Stories
      </h2>
      <Video
        aspectRatio="9:16"
        page={page}
        autoplay={false}
        content={{
          type: 'home',
          tags: [],
          video_id,
        }}
        muted={false}
        name="Tasty Story"
        posterImg={thumbnail_url}
        posterSize={600}
        showBigButton={true}
        shouldLoop={false}
        trackingFields={{
          position_in_subunit: 0,
          ...sharedTrackingFields
        }}
        videoUrls={videoUrls}
      />
      <div className="stories__caption xs-text-3 xs-my2">{caption}</div>
      {/* mobile button */}
      <FollowLink type="button" url={url} />
      {/* desktop link */}
      <FollowLink type="text" url={url} className="stories__link--desktop xs-text-3 xs-hide md-block" />
    </div>
  );
};

Stories.propTypes = {
  stories: PropTypes.array.isRequired,
  url: PropTypes.string.isRequired,
};

export default Stories;
