import React from 'react';
import PropTypes from 'prop-types';
import Feed from '../../Feed';
import SvgIcon from '../../svg/SvgIcon';

const BestRecipes = ({recipes}) => {

  return (
    <div className="best-recipes md-ml4 md-mr2">
      <div className="xs-relative">
        <svg className="best-recipes__blob" viewBox="0 0 377 125" fill="none" xmlns="http://www.w3.org/2000/svg">
          <mask id="mask0" maskUnits="userSpaceOnUse" x="0" y="0" width="377" height="125" style={{maskType:'alpha'}}>
            <rect x="2" y="2" width="373" height="121" rx="2" fill="white" stroke="#F4F4F4" strokeWidth="4"/>
          </mask>
          <g mask="url(#mask0)">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M456.217 119.499C463.844 114.938 470.303 108.7 475.045 101.278C476.865 100.183 478.327 98.374 478.971 95.7427C480.621 88.9856 481.731 82.1903 482.332 75.4053C483.002 73.3592 483.611 71.2823 484.138 69.1725C490.825 42.3949 484.309 14.4781 471.876 -9.54218C449.933 -51.9368 407.469 -77.647 366.248 -100.632C364.049 -103.427 361.925 -106.294 359.876 -109.241C342.573 -134.139 329.348 -161.777 313.926 -187.843C284.57 -237.461 250.977 -285.506 208.139 -324.511C165.545 -363.294 110.377 -396.842 50.8882 -395.565C-2.98952 -394.408 -47.5878 -360.102 -74.8891 -315.565C-104.38 -267.456 -113.602 -208.182 -106.344 -152.702C-91.5299 -39.496 -5.88124 54.9952 105.865 79.8269C167.166 93.4494 230.726 85.9699 292.314 97.2809C323.296 102.972 350.901 116.932 380.924 125.591C405.878 132.79 433.307 133.198 456.217 119.499"
              fill="#F9E932"/>
          </g>
        </svg>
        <div className="best-recipes__heading">
          <h2 className="best-recipes__overline"> THE BEST OF <span className="screenreader-only">Tasty</span></h2>
          <SvgIcon title="Tasty logo sidebar" role="img" id="tasty-logo" className="best-recipes__logo" />
        </div>
      </div>
      <div className="xs-mx2">
        <Feed
          columns={1}
          hasRightText
          items={recipes}
          hasLargeLabelMobile
          // CET
          subunitName="best_recipes"
          unitName='main'
          unitType='feed'
          positionInUnit={9}
        />
      </div>
    </div>
  );
};

BestRecipes.propTypes = {
  recipes: PropTypes.array,
};

export default BestRecipes;
